import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './timeline.scss';
import '../../generic-page.scss';

const RevGuidesBannerHistory: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse timeline-page'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Banner history (CN)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_banners_cn.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Banner history (CN)</h1>
          <h2>
            The history of banners released in the CN version of Reverse: 1999.
          </h2>
          <p>
            Last updated: <strong>02/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="CN Banner history" />
        <p>
          You can check the banner history for the CN server in this{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1PPUQn_EGUPaJr1UFMTpyC8f3d4TQ6px3GlgytxWsqVw/edit#gid=1065904935"
            target="_blank"
            rel="noreferrer"
          >
            spreadsheet.
          </a>
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevGuidesBannerHistory;

export const Head: React.FC = () => (
  <Seo
    title="Banner history (CN) | Reverse: 1999 | Prydwen Institute"
    description="The history of banners released in the CN version of Reverse: 1999."
    game="reverse"
  />
);
